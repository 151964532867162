const Avatar = ({ imageUrl, alt, profileImageStyles }) => {
  var objectFit = 'object-cover';
  var rounded = 'rounded-full';
  var width = 'w-24';
  if(profileImageStyles.includes('contain')) objectFit = 'object-contain';
  if(profileImageStyles.includes('square')) rounded = 'rounded-none';
  if(profileImageStyles.includes('full-width')) width = 'w-auto';
    return (
      <>
        <img className={`${rounded} ${width} h-24 ${objectFit}`} src={imageUrl} alt={alt} />
      </>
    );
};
  
export default Avatar;