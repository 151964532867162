import Ellipsis from "./Ellipsis";

const MoreButton = ({ title, description, url }) => {
    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share ' + title,
                    text: 'Check out this content!',
                    url: url,
                });
                console.log('Content shared successfully');
            } catch (error) {
                console.error('Error sharing content:', error);
            }
        } else {
            try {
                await navigator.clipboard.writeText(url);
                console.log('Link copied to clipboard');
            } catch (error) {
                console.error('Failed to copy:', error);
            }
        }
    };

   

    return (
        <button className="rounded-full min-w-10 h-10 w-10 flex justify-center items-center hover:bg-gray-200 flex-grow-0" type="button" onClick={handleShare}>
            <Ellipsis title={title} description={description} />
        </button>
    );
};

export default MoreButton;
