import MoreButton from "./MoreButton";
const LinkButton = ({ url, imageUrl, title, description }) => {
    return (
      <>
        <div className="flex bg-[rgb(255,255,255,.8)] border rounded-md p-1 shadow-md items-center hover:scale-[1.02] transition-all group">
            <a href={url} className="flex-grow flex items-center justify-between">
              {imageUrl ? <img className="rounded w-auto h-12 object-contain flex-grow-0" src={imageUrl} /> : <div className="rounded w-12 h-12"></div>}
              <p className="font-medium group-hover:scale-[.98] transition-all text-center flex-grow">{title}</p>
              <div></div>
            </a>
            <MoreButton title={title} url={url} description={description}/>
        </div>
      </>
    );
  };
  
  export default LinkButton;