const Ellipsis = ({  title, description }) => {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <title>More about {title}</title>
        <desc>{description}</desc>
        <path fill="currentColor" stroke="currentColor" d="M12.666 7.333c-0.368 0-0.666 0.299-0.666 0.667s0.298 0.667 0.666 0.667c0.368 0 0.667-0.299 0.667-0.667s-0.299-0.667-0.667-0.667z"></path>
        <path fill="currentColor" stroke="currentColor" d="M8 7.333c-0.368 0-0.667 0.299-0.667 0.667s0.299 0.667 0.667 0.667c0.368 0 0.667-0.299 0.667-0.667s-0.299-0.667-0.667-0.667z"></path>
        <path fill="currentColor" stroke="currentColor" d="M3.333 7.333c-0.368 0-0.667 0.299-0.667 0.667s0.299 0.667 0.667 0.667c0.368 0 0.667-0.299 0.667-0.667s-0.299-0.667-0.667-0.667z"></path>
     </svg>

    );
  };
  
  export default Ellipsis;