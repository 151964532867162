import Avatar from "./Avatar";
import MoreButton from "./MoreButton";

export function Header({ profileImage, pageTitle, profileImageStyles }) {
  return (
    <header className="flex flex-col items-center justify-center gap-3 mb-8">
      <div className="self-end rounded-full border border-slate-200 bg-slate-100">
        <MoreButton title={pageTitle} url={window.location.href} />
      </div>
      <Avatar imageUrl={profileImage} alt={pageTitle} profileImageStyles={profileImageStyles??""}/>
      <h1 className="text-slate-900 font-bold text-xl leading-normal tracking-tight">{pageTitle}</h1>
    </header>
  );
}
