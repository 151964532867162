import twitterIcon from './social/twitter.svg';
import { useState, useEffect } from "react";
import Loader from "./Loader";
import Footer from "./Footer";
import { ButtonList } from "./ButtonList";
import { Header } from "./Header";
import { Background } from './Background';



export default function App() {
  const [linkButtonsData, setLinkButtonsData] = useState([]);
  const [profileImage, setProfileImage] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingFailed, setFailed] = useState(false);
  const [profileImageStyles, setProfileImageStyles] = useState('');

  const backgroundStyle = {
    backgroundImage: 'url(' + profileImage + ')',
    // Add other styling properties as needed
};


  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    const toValue = params.get('to');

    // Simulate an API call
    const fetchData = async () => {
      try {
        setLoading(true);
        // Replace with your actual API call
        const response = await fetch(`https://lp-functions.azurewebsites.net/api/links/${toValue}`);
        const data = await response.json();
        // const data = {
        //   "Id": 0,
        //   "PageTitle": "@selenagomez",
        //   "ProfileImage": "./selenagomez.jpg",
        //   "LinkTreeLinks":
        //     [
        //       {
        //         "Title": "Listen to Single Soon",
        //         "Url": "https://SelenaGomez.lnk.to/SingleSoon",
        //         "ImageUrl": "https://ugc.production.linktr.ee/d88c7aaa-d53b-4ff6-aab4-fc447b8ae737_b10dddbb-da83-4a96-b10b-07af2b19dc54-artwork-600x315.jpeg?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Shop The Single Soon Collection",
        //         "Url": "https://SelenaGomez.lnk.to/OfficialShop",
        //         "ImageUrl": "https://ugc.production.linktr.ee/73dc5f5d-3f49-4e59-9d09-e5635455a98f_IMG-9029--1-.png?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Shop The Kind Words Collection",
        //         "Url": "https://store.selenagomez.com/",
        //         "ImageUrl": "https://ugc.production.linktr.ee/TTO6YkGGR6GD6L7PsFra_Unknown.png?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "My Mind & Me (Film Version)",
        //         "Url": "https://www.youtube.com/watch?v=NgsENBX-sQQ",
        //         "ImageUrl": "https://ugc.production.linktr.ee/ajJY4KGJQVK6JM5CYKyO_IMG_6675.jpg?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Listen to My Mind & Me",
        //         "Url": "https://SelenaGomez.lnk.to/MyMindAndMe",
        //         "ImageUrl": "https://ugc.production.linktr.ee/BoqDPdyaS1q5MC66SZpQ_Ff11cbxWAAANWbb.jpg?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Stream Now Selena Gomez: My Mind & Me | Apple TV+",
        //         "Url": "https://apple.co/selenagomezdoc",
        //         "ImageUrl": "https://ugc.production.linktr.ee/0JGbPvjSbKsPjPphdHDd_1200x675mf.jpg?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Listen to Calm Down with Rema",
        //         "Url": "https://rema.lnk.to/CalmDownwithSelenaGomezID",
        //         "ImageUrl": "https://ugc.production.linktr.ee/ETUVjV1bQPuk7ai1LPOa_IMG_7832.JPG?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Rare Beauty",
        //         "Url": "https://www.rarebeauty.com/",
        //         "ImageUrl": "https://ugc.production.linktr.ee/zJGjO0QSGnAjLqkwndlA_6da71ce36f2dba9365e8f23f972617bb?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Wondermind",
        //         "Url": "https://www.wondermind.com",
        //         "ImageUrl": "https://ugc.production.linktr.ee/fmxFrgmCQuG24O8p1pGo_Screen Shot 2021-11-29 at 8.13.05 PM.png?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Learn More About the Rare Impact Fund",
        //         "Url": "https://rareimpactfund.org/",
        //         "ImageUrl": "https://ugc.production.linktr.ee/7g8yzLBURcucMJ0jogiz_22tIYe8COTH0xRfj?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Donate to the Rare Impact Fund",
        //         "Url": "https://secure.donationpay.org/rareimpact/",
        //         "ImageUrl": "https://ugc.production.linktr.ee/v9tKDvLzRlSCiXSEpR1Z_404a58d6ef17c7411f53e58b13559f726?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Our Place x Selena Gomez collection",
        //         "Url": "https://fromourplace.com/collections/selena?utm_campaign=selena&utm_medium=affiliate&utm_source=influencer&utm_term=linktree",
        //         "ImageUrl": "https://ugc.production.linktr.ee/FYCnUA3WSgnpHukAsahR_product only.png?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       {
        //         "Title": "Serendipity 3",
        //         "Url": "https://serendipity3.com/",
        //         "ImageUrl": "https://ugc.production.linktr.ee/PQ80HaMlSnCFZhcMWfyb_images.png?io=true&size=thumbnail-stack-v1_0"
        //       },
        //       // ... additional entries
        //     ]
        // };
        // throw new error();
        setPageTitle(data.PageTitle);
        setProfileImage(data.ProfileImage);
        setLinkButtonsData(data.LinkTreeLinks);
        setProfileImageStyles(data.ProfileImageStyles);
        document.title = data.PageTitle;
      } catch (error) {
        setFailed(true)
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
    <div>
      <Loader />
    </div>
    );
  } 
  
  if (loadingFailed) {
    return (
      <div className="w-full flex justify-center text-center">
        <div className="max-w-4xl">
          We're having trouble loading the information you requested. <br />If this problem persists, please contact our support team at <a href="mailto:it@lifepro.com" className="text-blue-600 hover:underline decoration-blue-900 transition-all font-bold">it@lifepro.com</a>.
        </div>
      </div>
    )
  }

  return (
    <>
      <Background style={backgroundStyle} />
      <div>
        <Header profileImage={profileImage} pageTitle={pageTitle} profileImageStyles={profileImageStyles} />
        <ButtonList linkButtonsData={linkButtonsData} />
        <Footer />
      </div>
    </>
  );
}


