import facebookIcon from './social/facebook.svg';
import youtubeIcon from './social/youtube.svg';
import linkedInIcon from './social/linkedin.svg';

export default function Footer() {
  return (
    <footer className="flex items-center w-full mt-8 flex-col gap-2">
      <div className="social-media flex gap-2 max-w-96">
        <a className="h-10 w-10 flex justify-center items-center" href="https://www.facebook.com/LifeProFinancial/">
          <img src={facebookIcon} />
        </a>
        <a className="h-10 w-10 flex justify-center items-center" href="https://www.youtube.com/@LifeProUniversity">
          <img src={youtubeIcon} />
        </a>
        <a className="h-10 w-10 flex justify-center items-center" href="https://www.linkedin.com/company/lifepro">
          <img src={linkedInIcon} />
        </a>

      </div>
      <a href="https://www.lifepro.com" className="text-sm">www.lifepro.com</a>
    </footer>
  );
}
