import LinkButton from "./LinkButton";

export function ButtonList({ linkButtonsData }) {
  return (
    <section className="flex justify-center w-full">
      <div className="flex flex-col gap-4 w-full max-w-4xl">
        {linkButtonsData.map((button, index) => (
          <LinkButton key={index} title={button.Title} url={button.Url} imageUrl={button.ImageUrl} />
        ))}
      </div>
    </section>
  );
}
